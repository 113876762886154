header,
footer {
  background-color: var(--candy);
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  font-family: var(--raleway);
}

.navTitle {
  margin-left: 0;
  margin-right: 0;
  background-color: var(--raspberry);
  padding: 10px;
}

.navTitle:hover,
.navTitle:active {
  background-color: var(--candy);
}

.navTitle h1,
.navTitle h2 {
  color: var(--white);
}

.navTitle h1 {
  border-bottom: solid 1px var(--white);
}

.navTitle h1:hover,
.navTitle h1:active,
.navTitle h2:hover,
.navTitle h2:active {
  color: var(--raspberry);
}

/* burger nav animation */

.navbar button {
  background: transparent;
  border: none;
  outline: none;
  z-index: 5;
}

.burger-nav {
  width: 30px;
  height: 20px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  order: 3;
}

.burger-nav span {
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background: var(--accessible-white);
  border-radius: 4px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.burger-nav span:nth-child(1) {
  top: 0px;
}

.burger-nav span:nth-child(2) {
  top: 11px;
}

.burger-nav span:nth-child(3) {
  top: 22px;
}

.burger-nav.isopen span:nth-child(1) {
  top: 11px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

.burger-nav.isopen span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

.burger-nav.isopen span:nth-child(3) {
  top: 11px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.nav-menu {
  display: none;
  position: absolute;
  top: 110px;
  right: 10px;
  width: 95%;
  background-color: var(--raspberry);
}

.nav-menu.isopen {
  display: block;
  z-index: 10;
}

.nav-menu li {
  padding: 5px 10px;
  margin: 10px;
  border: dotted 5px var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.nav-menu li a {
  color: var(--accessible-white);
  width: 100%;
}

.nav-menu li a:hover,
.nav-menu li a:active {
  color: var(--grey);
}

@media (min-width: 500px) {
  .navTitle {
    margin: 10px;
  }

  .nav-menu {
    display: flex;
    order: 2;
    width: 60%;
    position: static;
    background-color: transparent;
  }

  .nav-menu li {
    padding: 5px;
    margin: 5px;
  }

  .nav-menu li a {
    color: var(--raspberry);
    font-size: 20px;
  }

  .nav-menu li a:hover,
  .nav-menu li a:active {
    color: var(--white);
  }

  .burger-nav {
    display: none;
  }
}

/* footer */

footer {
  height: 100px;
}

.contact {
  display: flex;
  padding: 20px 0;
}

.contact img {
  height: 50px;
  width: auto;
}
