.blockContainer {
  display: flex;
  flex-wrap: wrap;
  margin: 10px auto;
}

.blockItem {
  margin: 10px auto;
  width: 90%;
  list-style: none;
  border: solid var(--raspberry) 2px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.blockItem h3 {
  margin-bottom: 10px;
}

.blockItem p span {
  font-weight: bold;
}

.blockItem a {
  width: 100%;
  text-align: center;
}

.blockItem a h3:hover,
.blockItem a h3:active {
  color: var(--grey);
}

/* intro page */

.intro .blockItem h3 {
  margin: 10px 0 auto;
}

.intro p {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 10px 0;
}

/* cv page */

.blockItem .cfg {
  height: auto;
  width: 90%;
  margin: 5px auto;
}

.blockItem .blogging {
  height: 100px;
  width: auto;
  margin: 5px auto;
}

/* skills page */
.techSkills .blockContainer {
  /* background-color: var(--raspberry); */
  justify-content: center;
  padding: 5px 0;
  color: var(--grey);
}

.techSkills .blockItem {
  border: solid 2px var(--raspberry);
  margin: 5px;
}

.techSkills p {
  font-family: var(--raleway);
  text-align: justify;
}

.skillsInfo {
  margin-bottom: auto;
}

.devicons,
.deviconImages {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.devicons {
  margin: 10px 5px;
}

.devicons i,
.techSkills img {
  margin: 10px;
  transition-timing-function: ease-in-out;
}

.devicons i {
  font-size: 65px;
  color: var(--accessible-white);
}

.devicons i:hover {
  transform: scale(1.5);
}

.techSkills img {
  height: 45px;
  width: 65px;
}

.techSkills img:hover {
  transform: scale(1.5);
}

.techSkills .alexa,
.techSkills .unity {
  height: 40px;
}

.techSkills .google {
  height: 50px;
}

.techSkills .threejs {
  background-color: var(--grey);
  /* margin: 10px auto; */
}

/* projects */

.Projects p {
  text-align: center;
}

.Projects .blockItem img {
  width: 100%;
  height: auto;
}

.Projects .blockItem:hover {
  transform: scale(1.2);
  background: var(--raspberry);
}

.Projects .blockItem:hover h3 {
  color: var(--accessible-white);
}

/* tablet */
@media (min-width: 450px) {
  .blockItem {
    width: 45%;
  }
}

/* desktop */
@media (min-width: 1000px) {
  .blockItem {
    width: 30%;
  }

  .techSkills .blockItem {
    width: 45%;
  }

  .intro .blockItem {
    width: 23%;
  }
}

/* Style The Dropdown Button */
.dropbtn {
  background-color: var(--raspberry);
  font-family: var(--raleway);
  color: var(--accessible-white);
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--white);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content.isopen {
  display: block;
}
